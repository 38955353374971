import * as Sentry from "@sentry/nuxt";

export function logError(error: any) {
  console.error(error);

  const response =
    error.response ||
    (error.data && { data: error.data.data, status: error.data.statusCode });

  if (response) {
    console.error(response.status, response.data, response.config);

    Sentry.addBreadcrumb({
      category: "fetch-error",
      message: error.message,
      level: "error",
      data: {
        status: response.status,
        data: JSON.stringify(response.data),
        config: response.config,
      },
    });
  }

  Sentry.captureException(error);
}
